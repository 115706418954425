<template>
  <div class="bgcard">
    <div class="searchlist">
      <div class="searchtime" style="margin-right: 0.4rem">
        <span style="color: #bbbbbb;white-space:nowrap;">{{ $t("lang.time") }}：</span>
        <el-date-picker
          v-model="time"
          type="daterange"
          :range-separator="$t('lang.to')"
          :start-placeholder="$t('lang.Startdate')"
          :end-placeholder="$t('lang.Enddate')"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="picker"
        >
        </el-date-picker>
      </div>
      <div class="select_b" style="margin-right: 0.2rem">
        <span style="color: #bbbbbb">{{ $t("lang.Logtype") }}：</span>
        <el-select
          style="height: 0.4rem; width: 1.5rem"
          v-model="value1"
          :placeholder="$t('lang.Please')"
          popper-class="select_a"
          @change="stauts"
        >
          <el-option :label="$t('lang.whole')" value=""></el-option>
          <el-option :label="$t('lang.Operationlog')" :value="1"></el-option>
          <el-option :label="$t('lang.systemlog')" :value="2"></el-option>
        </el-select>
      </div>
      <div class="query">
        <div class="inputname">
          <input
            v-model="input"
            :placeholder="$t('lang.Pleaseenterthedevicename')"
          />
        </div>
        <div class="searchbtn">
          <button @click="sear">
            <p>{{ $t("lang.search") }}</p>
          </button>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column
          prop="name"
          :label="$t('lang.equipmentName')"
          align="center"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column :label="$t('lang.Logtype')" align="center">
          <template #default="scope">
            <span v-if="scope.row.type == 1">{{
              $t("lang.Operationlog")
            }}</span>
            <span v-else>{{ $t("lang.systemlog") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="time"
          :label="$t('lang.Latestlogtime')"
          align="center"
        >
        </el-table-column>
        <el-table-column :label="$t('lang.describe')" align="center">
					<template #default="scope">
						<span>{{scope.row.desc |text}}</span>
					</template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.operation')"
          align="center"
          width="150"
        >
          <template #default="scope">
            <el-tooltip effect="dark" :content='$t("lang.details")' placement="left">
              <div
                class="bottom_imgs bottom_detail"
                @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                @click="detailsmsg(scope.row)"
              >
                <img
                  class="imgOne"
                  v-if="scope.row.flag1 === 0"
                  src="@/assets/imgs/bottom/xiangqing.png"
                  alt=""
                />
                <img
                  class="imgTwo"
                  v-if="scope.row.flag1 === 1"
                  src="@/assets/imgs/bottom/xiangqing2.png"
                  alt=""
                />
              </div>
            </el-tooltip>
            <!-- <el-button
              class="buttons"
              size="small"
              style="background-color: #1061c5"
              @click="detailsmsg(scope.row)"
              >{{ $t("lang.details") }}</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div
        style="display: flex; align-items: center"
        v-if="tableData.list && tableData.list.length > 0"
      >
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 详情模态框 -->
    <el-dialog
      :title="$t('lang.Logdetails')"
      :visible.sync="showSee"
      :show-close="false"
      width="8.5rem"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <div class="details">
        <div class="binding" v-for="(item, index) in description" :key="index">
          <div>
            <label>{{ $t("lang.Operator") }}：</label>
            <span>{{ item[0] }}</span>
          </div>
          <div style="margin: 0 0.3rem">
            <label>{{ $t("lang.Operationtime") }}：</label>
            <span>{{ item[1] }}</span>
          </div>
          <div>
            <label>{{ $t("lang.pedagogicaloperation") }}：</label>
            <span>{{ item[2] |text }}</span>
          </div>
        </div>
      </div>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="showSee = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as pment from "@/api/equipment.js";
export default {
  data() {
    return {
      seenOnetext: this.$t("lang.binding"),
      seenTwotext: this.$t("lang.Unbundling"),
      seenThreetext: this.$t("lang.edit"),
      seenFivetext: this.$t("lang.deletes"),
     
      time: "",
      value1: "",
      input: "",
      parsm: {
        page: 1,
        size: 10,
        name: "",
        type: "",
        startTime: "",
        stopTime: "",
        email: "",
      },
      tableData: [],
      showSee: false,
      description: [],
    };
  },
  mounted() {
    this.parsm.email = JSON.parse(sessionStorage.getItem("user")).email;
    this.getList();
  },
	filters:{
		text(val){
			let zhon = sessionStorage.getItem("language");
			//console.log(zhon)
			let text=''
			if(val){
				let sk=val.split(',')
				if(zhon=='zhong'){
					text=sk[0]
				}else{
					text=sk[1]
				}
			}
			return text
		}
	},
  methods: {
    enterOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1 ) {
          if(ids == this.tableData.list[i].id && flag === 0){
            this.tableData.list[i].flag1 = 1
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
       for (let i in this.tableData.list) {
        if (index === 1 ) {
          if(ids == this.tableData.list[i].id && flag === 1){
            this.tableData.list[i].flag1 = 0
          }
        }
      }
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //获取设备日志列表
    async getList() {
      let res = await pment.journallist(this.parsm);
      if (res) {
        res.data.list.forEach((item) => {
          item.flag1 = 0;
        });
        this.tableData = res.data;
      }

      console.log(res);
    },
    //时间筛选
    picker(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.startTime = e[0];
        this.parsm.stopTime = e[1];
      } else {
        this.parsm.startTime = "";
        this.parsm.stopTime = "";
      }
      this.getList();
    },
    //类型筛选
    stauts(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.type = e;
      this.getList();
    },
    //名称筛选
    sear() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.name = this.input;
      this.getList();
    },
    //日志详情
    async detailsmsg(row) {
      this.showSee = true;
      let res = await pment.journaldetails({
        mid: row.deviceMid,
        email: this.parsm.email,
      });
      if (res.data) {
        this.description = res.data;
      }
      console.log(res);
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.button_a {
  border: none;
  outline-style: none;
  margin-top: 0.2rem;
  width: 0.92rem;
  height: 0.45rem;
  background: #ededed;
  border: 0.01rem solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}
.details {
  max-height: 2.5rem;
  overflow: auto;
}
.binding {
  display: flex;
  margin-bottom: 0.2rem;
  font-size: 0.15rem;
  color: #606266;
  label {
    width: 1rem;
  }
  span {
    color: #000000;
  }
}
/deep/.bottom_detail{
	width: .3rem;
	margin-left: .5rem;
}
</style>
